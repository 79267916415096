import { Subset } from '../generics';
import { enumOptionsFromEnum } from '../helperFunctions';
import { ContractType } from './contract';
import { BaseDiscrepancy } from './discrepancy';
import { YN } from './enums';
import { Impute } from './impute';

export enum CommonBudgetElementGroups {
  QC_SUPPLIER = 1,
  QC_CUSTOMER = 2,
  LOGISTICS_SUPPLIER = 3,
  LOGISTICS_CUSTOMER = 4,
}

export enum Prorating {
  AMOUNT = 1,
  QUANTITY = 2,
}

export const Proratings = enumOptionsFromEnum(Prorating);

export type BudgetElementGroup = {
  id: CommonBudgetElementGroups;
  name: string;
  budgetElements: BudgetElement[];
};

export enum BudgetElementType {
  MARITIME_FREIGHT = 1,
  THIRD_PARTY_INVOICE = 48,
  ADDRESS_COMMISION = 52,
  AUTOMATIC_MARITIME_FREIGHT = 50,
  BC_ON_PURCHASES = 30,
  BC_ON_SALES = 26,
  BROKERAGE = 25,
  BROKERAGE_INVOICE = 76,
  BUNKER_CONSUMPTION = 32,
  BUNKERS = 27,
  CFD_ON_PURCHASE = 37,
  CFD_ON_SALE = 38,
  COMMISSION_ON_PURCHASES = 33,
  COMMISSION_ON_SALES = 12,
  CONTRACT_CESSION = 35,
  CREDIT_ADVICE = 21,
  CREDIT_INSURANCE = 29,
  DAILY_VARIATION_MARGIN_PAYMENT = 72,
  DEMURRAGES = 14,
  DIFF_INTEREST = 22,
  DISCOUNT_FEES = 45,
  DISCOUNT_ON_NEXT_DEAL = 63,
  DOCUMENTARY_REMITTANCE_ON_PURCHASE = 18,
  DOCUMENTARY_REMITTANCE_ON_SALE = 19,
  LAND_FREIGHT = 2,
  STORAGE = 3,
  INTEREST = 11,
  INSPECTION = 5,
  INSURANCE = 4,
  LC_FEES = 7,
  OTHER_REVENUES = 10,
  OTHER_EXPENSES = 13,
  PRICE_ADJUSTMENT_ON_SALES = 28,
  PRICE_ADJUSTMENT_ON_PURCHASES = 44,
  TC_CONSUMPTION = 34,
  LC_CONFIRMATION_FEES = 39,
  LC_ADVISING_FEES = 40,
  LC_AMENDMENT_FEES = 42,
  LC_DISCREPANCIES_FEES = 43,
  LC_DISCOUNT_CHARGES = 49,
  QUALITY_CLAIMS = 46,
  QUALITY_ADJUSTMENT_ON_SALES = 77,
  QUALITY_ADJUSTMENT_ON_PURCHASES = 78,
}

export const BudgetElementTypes = enumOptionsFromEnum(BudgetElementType);

export enum BudgetContactRequired {
  NONE = 0,
  OPTIONAL = 1,
  REQUIRED = 2,
}

export type BudgetElement = {
  id: number;
  name: string | null;
  budgetElementType: BudgetElementType | null;
  logicalOrder: number | null;
  removeFromAccrualsOnPayment: string | null;
  excludeFromAccruals: YN | null;
  excludeFromCrossing: string;
  includeInOpenInvoice: YN | null;
  allowedForSupplierPayment: YN | null;
  allowedForClientPayment: YN | null;
  grossWeight: YN;
  bookQuantity: string;
  modifyDate: number | null;
  modifiedBy: string | null;
  creationDate: number | null;
  createdBy: string | null;
  versionNb: number;
  prorating: Prorating | null;
  printWithMerchPay: string;
  passThruInventory: string | null;
  contractType: ContractType | null;
  useAccountingIntegration: YN | null;
  thirdPartyAllowed: YN;
  requiresOceanRate: YN;
  archived: YN;
  budgetContactRequired: BudgetContactRequired;
  supplierDiscrepancies?: BaseDiscrepancy[];
  customerDiscrepancies?: BaseDiscrepancy[];
  groups?: BudgetElementGroup[];
  imputes?: Impute[];
};

export type BudgetElementUsage = {
  id: number;
  name: string;
  budgetElementType: BudgetElementType;
  contractType: ContractType;
  prorating: Prorating;
  excludeFromAccruals: YN;
  includeInOpenInvoice: YN;
  allowedForSupplierPayment: YN;
  allowedForClientPayment: YN;
  useAccountingIntegration: YN;
  archived: YN;
  inUse: YN;
};

export type BudgetElementAccounting = {
  codeImpute: number | null;
  companyId: number | null;
  companyName: string | null;
  id: number;
  name: string;
  budgetElementType: BudgetElementType;
  contractType: ContractType;
  prorating: Prorating;
  excludeFromAccruals: YN;
  useAccountingIntegration: YN;
  archived: YN;
  accKey: number | null;
  accCode: string | null;
  accName: string | null;
  currAbbr: string | null;
  idenLong: string | null;
  remk: string | null;
};

export type UpsertBudgetElementRequest = Subset<BudgetElement, 'name' | 'budgetElementType' | 'contractType' | 'prorating' | 'excludeFromAccruals' | 'useAccountingIntegration', 'id'> & {
  imputes: UpsertBudgetElementAccounting[];
};

export type UpsertBudgetElementAccounting = Subset<Impute, 'companyId' | 'accKey' | 'budgetElementKey', 'codeImpute' | 'remk' | 'archived'> & {
  isUpdated: boolean;
};

export type BudgetElementForm = Subset<BudgetElement, 'name' | 'budgetElementType' | 'contractType' | 'prorating' | 'excludeFromAccruals' | 'useAccountingIntegration', 'id'> & {
  imputes?: BudgetElementAccountingForm[];
};

export type BudgetElementAccountingForm = Subset<Impute, 'companyId' | 'glAccount', 'budgetElementKey' | 'codeImpute' | 'archived' | 'remk'> & {
  linePosition: number;
  isUpdated: boolean;
};

export type ArchiveUnarchiveBudgetElement = Pick<BudgetElement, 'id' | 'archived'>;

export const upsertBudgetElement = async (formVal: BudgetElementForm) => {
  const budgetElementsAcc: BudgetElementAccountingForm[] = [];
  if (formVal.imputes && formVal.imputes.length > 0) {
    for (const impute of formVal.imputes) {
      if (impute.companyId && impute.glAccount) {
        budgetElementsAcc.push(impute);
      }
    }
  }
  const showAccountings = formVal.useAccountingIntegration !== YN.Y;
  const request: UpsertBudgetElementRequest = {
    id: formVal.id ?? null,
    name: formVal.name,
    budgetElementType: formVal.budgetElementType,
    contractType: formVal.contractType,
    prorating: formVal.prorating,
    excludeFromAccruals: formVal.excludeFromAccruals,
    useAccountingIntegration: formVal.useAccountingIntegration,
    imputes: showAccountings
      ? budgetElementsAcc.map((impute) => {
          return {
            companyId: impute.companyId,
            accKey: impute.glAccount.id,
            budgetElementKey: impute.budgetElementKey ?? null,
            codeImpute: impute.codeImpute ?? null,
            remk: impute.remk ?? '',
            archived: impute.archived ? impute.archived : YN.N,
            isUpdated: impute.isUpdated,
          };
        })
      : [],
  };
  return request;
};
